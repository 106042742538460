import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview } from '../index';

const NSCLC_1L_COMBO_NSQ = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview 
            title="Study Overview<sup>1</sup>" 
            description="Phase 3, randomized, multicenter, double-blind, active-controlled trial in systemic therapy–naïve patients with metastatic nonsquamous NSCLC, regardless of PD&#8288;-&#8288;L1 tumor expression status and with no EGFR or ALK genomic tumor aberrations. Patients with autoimmune disease that required systemic therapy within 2 years of treatment; a medical condition that required immunosuppression; or patients who had received more than 30 Gy of thoracic radiation within the prior 26 weeks were ineligible. Patients were randomized to receive KEYTRUDA 200&nbsp;mg, cisplatin or carboplatin, and pemetrexed intravenously every 3 weeks (Q3W) for 4 cycles followed by KEYTRUDA 200&nbsp;mg for up to 24 months and pemetrexed Q3W (n=410); placebo, cisplatin or carboplatin, and pemetrexed intravenously Q3W for 4 cycles followed by pemetrexed Q3W (n=206). Treatment continued until progression of disease or unacceptable toxicity. Primary efficacy outcome measures were OS and PFS assessed by blinded independent central review (BICR) per Response Evaluation Criteria In Solid Tumors v1.1 (RECIST v1.1) (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ). Secondary efficacy outcome measures were ORR and duration of response (DOR). ORR and DOR were also assessed by BICR per RECIST v1.1 (modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ). Patients receiving chemotherapy alone who experienced disease progression could cross over to receive KEYTRUDA as monotherapy."
        />
    </ComponentWrapper>
);

export default NSCLC_1L_COMBO_NSQ;